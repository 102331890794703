<template>
  <div class="space-y-4">
    <asom-modal
      title="Edit PSM Manual Record"
      v-model="showModal"
      :dismissible="false"
    >
      <p>
        This will edit PSM Transaction
        {{ this.$route.params.transactionNo || "" }}
      </p>
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-card>
      <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-alert
          class="col-span-2"
          v-if="error"
          variant="error"
          :error-message="error"
        />
        <asom-form-field
          label="PSM"
          required
          :state="inputStates('formData.psmId')"
          error="PSM is required"
        >
          <asom-input-select
            :objectModelValue="false"
            v-model="formData.psmId"
            :state="inputStates('formData.psmId')"
            :options="psmOptions"
          />
        </asom-form-field>
        <asom-form-field
          class="col-span-2"
          label="Input Type"
          required
          :state="inputStates('formData.inputType')"
          error="Input Type required"
        >
          <asom-input-radio-group
            horizontal
            v-model="formData.inputType"
            :options="inputTypes"
          />
        </asom-form-field>
        <asom-form-field
          label="GTM/TUK"
          v-if="isGTMRequired"
          required
          :state="inputStates('formData.gtmId')"
          error="GTM/TUK is required"
        >
          <asom-input-select
            :objectModelValue="false"
            v-model="formData.gtmId"
            :state="inputStates('formData.gtmId')"
            :options="gtmOptions"
          />
        </asom-form-field>
        <div v-if="isGTMRequired"></div>
        <div class="space-y-4">
          <asom-form-field
            label="Input"
            required
            :state="inputStates('formData.input')"
            :error="
              this.inputValidation
                ? 'Please enter a valid 16 digit number'
                : 'Input is required'
            "
          >
            <asom-input-select
              v-if="showInputOptions"
              v-model="formData.input"
              :state="inputStates('formData.input')"
              :options="inputOptions"
              :searchable="true"
              :objectModelValue="false"
            />
            <asom-input-text
              v-else
              type="text"
              v-model="formData.input"
              :state="inputStates('formData.input')"
            />
          </asom-form-field>
          <asom-form-field
            v-if="showPassengerSignature && getAttachmentId"
            label="Passenger Signature"
            description="Not editable"
          >
            <asom-signature-view 
              :file-id="getAttachmentId"
            />
          </asom-form-field>
          <asom-form-field
            class="row-span-2"
            label="Description"
            required
            :state="inputStates('formData.description')"
            error="Description is required"
          >
            <asom-input-textarea
              rows="4"
              v-model="formData.description"
              placeholder="Add Description"
              :state="inputStates('formData.description')"
            />
          </asom-form-field>
        </div>
        <div class="space-y-4">
          <asom-form-field
            label="Waived"
            required
            :state="inputStates('formData.waived')"
            error="Waived is required"
          >
            <asom-input-text
              prependText="-"
              appendText="$"
              type="number"
              min="0"
              v-model="formData.waived"
              :state="inputStates('formData.waived')"
            />
          </asom-form-field>
          <asom-form-field
            label="Payment"
            required
            :state="inputStates('formData.payment')"
            error="Payment is required"
          >
            <asom-input-text
              prependText="-"
              appendText="$"
              type="number"
              min="0"
              v-model="formData.payment"
              :state="inputStates('formData.payment')"
            />
          </asom-form-field>
          <asom-form-field
            label="Receipt"
            required
            :state="inputStates('formData.receipt')"
            error="Receipt is required"
          >
            <asom-input-text
              prependText="+"
              appendText="$"
              type="number"
              min="0"
              v-model="formData.receipt"
              :state="inputStates('formData.receipt')"
            />
          </asom-form-field>
        </div>
        <asom-form-field
          v-if="uploadaAvalabilityChecked"
          class="col-span-2"
          label="Supporting documents"
          :state="inputStates('formData.files')"
          error="Supporting documents are required"
        >
          <asom-upload
            v-model="formData.files"
            :category="attachmentCategories.CASH_MANAGEMENT"
            :state="inputStates('formData.files')"
          />
        </asom-form-field>

        <asom-form-field
          class="row-span-2"
          label="Remarks"
          required
          :state="inputStates('formData.remarks')"
          error="Remarks is required, please enter more than 10 characters"
        >
          <asom-input-textarea
            rows="4"
            v-model="formData.remarks"
            placeholder="Add Remarks"
            :state="inputStates('formData.remarks')"
          />
        </asom-form-field>
        <div class="col-span-2 flex justify-end space-x-4">
          <asom-button
            text="Back"
            variant="secondary"
            @click="
              this.$router.push({
                name: 'View Manual Record',
                params: { transactionId: this.$route.params.transactionId },
              })
            "
          />
          <asom-button
            text="Submit"
            @click="submitClicked"
            :loading="isSubmitting"
          />
        </div>
      </form>
    </asom-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import { required, numeric, minLength, maxLength } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import {
  editPsmLog,
  getPSMDetails,
  listPsmInputFieldOptions,
} from "../../../../services/cashManagement.service";
import { imageDataToBlob } from "../../../../helpers/imageDataToBlob";
import { uploadFile } from "../../../../services/file.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  name: "EditManualRecord",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      formData: {
        accountId: null,
        psmId: null,
        inputType: null,
        input: null,
        gtmId: null,
        passengerSignature: null,
        passengerSignatureId: null,
        waived: null,
        description: null,
        payment: null,
        receipt: null,
        remarks: null,
        files: [],
      },
      inputValidation: false,
      uploadaAvalabilityChecked: false,
      isLoading: false,
      isSubmitting: false,
      error: null,
      showModal: false,
    };
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) {
        this.formData.files = [];
        this.getPSMRecordDetails();
      }
    },
    "formData.inputType": function(newValue) {
      if (newValue == 2) {
        this.inputValidation = true;
      } else {
        this.inputValidation = false;
      }
      this.formData.input = null;
    },
  },
  validations() {
    let rules = {
      formData: {
        psmId: { required },
        inputType: { required },
        input: this.inputValidation
          ? {
              required,
              numeric,
              minLength: minLength(16),
              maxLength: maxLength(16),
            }
          : { required },
        waived: { required },
        description: { required },
        payment: { required },
        receipt: { required },
        remarks: { required },
      },
    };

    switch (this.formData.inputType) {
      case "1":
        rules.formData.passengerSignature = { required };
        break;
      case "3":
      case "4":
        rules.formData.gtmId = { required };
        break;
      default:
        break;
    }

    return rules;
  },
  computed: {
    ...mapGetters({
      gtmOptions: "cashManagement/stationCashAccounts/gtmOptions",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
    }),
    inputTypes() {
      return [
        { value: "1", label: "Passenger name", description: "" },
        { value: "2", label: "CAN ID", description: "" },
        { value: "3", label: "Notebox No.", description: "" },
        { value: "4", label: "Coinbox No.", description: "" },
        { value: "5", label: "PSM Top-up", description: "" },
        { value: "6", label: "Others", description: "" },
      ];
    },
    pscId() {
      return get(this.formData, "accountId");
    },
    psmOptions() {
      const psmList = this.$store.getters[
        "cashManagement/stationCashAccounts/getPSMOfPSC"
      ](this.pscId);
      return psmList.map((psm) => ({
        value: psm.stationPSMId,
        label: psm.name,
      }));
    },
    isGTMRequired() {
      return [3, 4, "3", "4"].includes(this.formData.inputType);
    },
    showPassengerSignature() {
      return (
        this.formData.inputType && this.formData.inputType.toString() === "1"
      );
    },
    inputOptions(){
      return listPsmInputFieldOptions();
    },
    showInputOptions(){
      return this.formData.inputType && this.formData.inputType.toString() === "6";
    },
    getAttachmentId(){
      return get(this.formData.passengerSignature, "attachmentId", null);
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  mounted() {
    this.getPSMRecordDetails();
  },
  methods: {
    async getPSMRecordDetails() {
      const result = await getPSMDetails({
        id: get(this.$route.params, "transactionId"),
      });
      if (result.success) {
        this.setTransactionData(get(result.payload, "data"));
      }
    },
    setTransactionData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      };
      this.formData.inputType = data.inputType.toString()
      if(data.passengerSignature){
        this.formData.passengerSignatureId = data.passengerSignature.attachmentId
      }
      this.setTransactionAttachments(get(data, "attachments"));
    },
    setTransactionAttachments(attachments) {
      if (attachments.length > 0) {
        attachments.forEach((attachment) => {
          let obj = {};
          obj.fileId = get(attachment, "attachmentId");
          obj.fileName = get(attachment, "fileName");
          obj.size = 0;
          this.formData.files.push(obj);
        });
      }
      this.uploadaAvalabilityChecked = true;
    },
    submitClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.showModal = true;
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;

      const needUploadSignature =
        this.formData.inputType === "1" &&
        this.formData.passengerSignatureId == null;
      if (needUploadSignature) {
        const signatureBlob = imageDataToBlob(
          this.formData.passengerSignature,
          "passenger_signature"
        );
        const uploadResult = await uploadFile([signatureBlob], ATTACHMENT_CATEGORIES.CASH_MANAGEMENT);
        if (uploadResult.success) {
          this.formData.passengerSignatureId = get(
            uploadResult,
            "payload.files.0.fileId"
          );
        } else {
          this.isSubmitting = false;
          this.showModal = false;
          this.error = result.payload;
          this.$scrollTop();
          return;
        }
      }

      let uploadedIds = [];
      if (this.formData.files) {
        uploadedIds = this.formData.files.map((file) => file.fileId);
      }
      const result = await editPsmLog({
        id: get(this.$route.params, "transactionId"),
        transactionNo: get(this.formData, "transactionNo"),
        stationPSMId: this.formData.psmId,
        inputType: this.formData.inputType,
        input: this.formData.input,
        gtmId: this.isGTMRequired ? this.formData.gtmId : null,
        description: this.formData.description,
        passengerSignatureId: this.showPassengerSignature
          ? this.formData.passengerSignature.attachmentId
          : null,
        waived: this.formData.waived,
        payment: this.formData.payment,
        receipt: this.formData.receipt,
        attachmentIds: uploadedIds,
        remarks: this.formData.remarks,
        isVoided: false,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      this.isSubmitting = false;
      this.showModal = false;
      if (result.success) {
        this.$router.push({ name: "PSM Manual Record Main Page" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
